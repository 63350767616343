import { IonCol, IonContent, IonGrid, IonItem, IonItemGroup, IonLabel, IonList, IonListHeader, IonPage, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import './TermsOfUse.scss';
import { fetchTermsOfUseContent } from "../../api/API";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const TermsOfUse: React.FC = () => {

const fetchContent: any = async () => {  return await  fetchTermsOfUseContent(); } //call to /api promise defined
const dispatch = useDispatch();
const termsofuseContent = useSelector((state: RootStateOrAny) => state?.termsofusecontent); //retrive redux state from the store

const addTermsOfUseContent:any = (cont:any) => {  //redux action and object defined
  return {
      type: "addedTermsOfUseOnce",
      payload: cont,
  };
};

const callTermsOfUseApi = () => {
  fetchContent().then((a:any) => { //call API promise to fetch content
    dispatch(addTermsOfUseContent(a)); //dispatch to redux reducer
  });
  return;
}

useEffect(() => {
  callTermsOfUseApi(); //call the api on page load once
}, []);

/*sole function to process all the terms of use content items from the nosql collection api*/
const ProcessContentItems: any = () => {
  let result: any[] = [];
  let pCount: number = 0;
  termsofuseContent.map((data: any, i:any) => {   
    result.push( Object.values(data.content).map((paragraphs:any, index:any) => { 
    pCount++;
    return ( 
      (data.heading=='maincontent') ? ( 
        <IonItem key={pCount} class="no-underline">
          <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
            {paragraphs}
          </p>
        </IonItem>) :
      (index==0 && data.heading!=='maincontent') ? (
      <div key={pCount}>
        <IonListHeader>
            <IonLabel class="list-header-label">{data.heading}</IonLabel>
        </IonListHeader>
        <IonItem class="no-underline">
        <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
          {paragraphs}
        </p>
        </IonItem>
      </div>
      ) :
      (<IonItem key={pCount} class="no-underline">
        <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
          {paragraphs}
        </p>
        </IonItem>) 
    );
    }
  ))
  })
  return result 
};

const GenerateTermsOfUseHtml = () => {
  return (
      <>
    <IonRow>
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile"}>
        <IonLabel class="top-list-header-label">Terms of Use</IonLabel>
    </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile"}>
        <IonList lines="none">
          <ProcessContentItems/>
        </IonList>
      </IonItem>
    </IonRow>
    </>    
  );
};

return (
<IonPage>
  <IonContent>
   <IonItemGroup class="form-border-all">
      <IonGrid>
        <IonRow class="ion-justify-content-center">
          <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">
            <GenerateTermsOfUseHtml/>
          </IonCol>   
        </IonRow>    
      </IonGrid>
    </IonItemGroup>
  </IonContent>
</IonPage>
);

};

export default TermsOfUse;