import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonButton,
         IonHeader, IonFooter, IonContent, IonToolbar, IonTitle, IonItem, IonGrid, IonCol, IonRow, IonItemGroup,
         IonItemDivider,  IonModal, IonSelect, IonSelectOption, isPlatform, setupConfig,
         IonMenu,
         IonMenuToggle,
         IonButtons,
         IonMenuButton,
         IonList
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { square, archive, today, list, menuOutline } from 'ionicons/icons';
import CentripetHome from '../CentripetHome/Home';
import Sectors from '../Sectors/Sectors';
import Services from '../Services/Services';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import Contact from '../Contact/Contact';
import About from '../About/About';
import Sitemap from '../Sitemap/Sitemap';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from '../TermsOfUse/TermsOfUse';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

const MenuDesktop: React.FC = () => {

  return (
    <>
<IonHeader class="brand-name">
<IonGrid>
<IonRow class="ion-justify-content-center">
<IonCol size-xs="12" size-sm="9" size-md="10" size-lg="10" size-xl="9">
    <IonRow>
      <IonCol size="10">
            <div className="subheader"> Software & IT services company to keep you on target</div>
      </IonCol>
    </IonRow>
  </IonCol>
  </IonRow>          
  </IonGrid>         
</IonHeader> 

<IonContent class="no-scroll">
<IonItemGroup class="form-border-all">

<IonReactRouter>
  <IonTabs size-xs="6" size-sm="9" size-md="10" size-lg="10" size-xl="9">
    <IonRouterOutlet class="inner-page">
      <Route path="/centripetHome" component={CentripetHome} exact={true} />
      <Route path="/sectors" component={Sectors} exact={true} />
      <Route path="/services" component={Services} exact={true} />
      <Route path="/serviceDetails" component={ServiceDetails} exact={true} />
      <Route path="/contact" component={Contact} />
      <Route path="/about" component={About} />
      <Route path="/sitemap" component={Sitemap} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/termsOfUse" component={TermsOfUse} />
      <Route path="/" render={() => <Redirect to="/centripetHome" />} exact={true} />
    </IonRouterOutlet>
    <IonTabBar slot="top" class="custom-background">
    <IonTabButton><img src='assets/icon/icon_main.png' className="main-icon" /></IonTabButton>
    <IonTabButton tab="centripet" href="/centripetHome" layout="icon-start" class="brand-tab">
        <IonLabel class="brand-menu-label">centripet</IonLabel>
      </IonTabButton>
      <IonTabButton tab="Sectors" href="/sectors" layout="icon-start" class="cust-tab">
        <IonLabel class="main-menu-label">Sectors</IonLabel>
      </IonTabButton>
      <IonTabButton tab="Services" href="/services" layout="icon-start" class="cust-tab">
        <IonLabel class="main-menu-label">Services</IonLabel>
      </IonTabButton>
      <IonTabButton tab="Contact" href="/contact" layout="icon-start" class="cust-tab">
        <IonLabel class="main-menu-label">Contact</IonLabel>
      </IonTabButton>
      <IonTabButton tab="About" href="/about" layout="icon-start" class="cust-tab">
        <IonLabel class="main-menu-label">About</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </IonTabs>
</IonReactRouter>

  </IonItemGroup>
</IonContent>
    </>
  );
};

export default MenuDesktop;