import { IonCol, IonContent, IonGrid, IonItem, IonItemGroup, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import './Sitemap.scss';
import { Link } from "react-router-dom";
//const hostname = 'https://www.centripet.com';
const hostname = 'localhost';

const Sitemap: React.FC = () => {

const [sitemapHtlm, setSitemapHtlm] = useState("") as any;

const generateSitemapHtml = () => {
 
  return (
     <IonList lines="none">
      <IonItem class="no-underline"><Link to="/centripetHome">Home</Link></IonItem>
      <IonItem class="no-underline"><Link to="/sectors">Sectors</Link></IonItem>
      <IonItem class="no-underline"><Link to="/services">Services</Link></IonItem>
      <IonItem class="no-underline"><Link to="/contact">Contact</Link></IonItem>
      <IonItem class="no-underline"><Link to="/about">About</Link></IonItem>
      <IonItem class="no-underline"><Link to="/privacyPolicy">Privacy Policy</Link></IonItem>
      <IonItem class="no-underline"><Link to="/termsOfUse">Terms Of Use</Link></IonItem>
     </IonList>
  );
  };

useEffect(() => {
  setSitemapHtlm(generateSitemapHtml());
}, []);

return (
  <IonPage>
  <IonContent>
   <IonItemGroup class="form-border-all">
        <IonGrid>
        <IonRow class="ion-justify-content-center">

        <IonCol size="14" size-md="10" size-lg="10" size-xl="9">
          <IonRow class="block-display">
            <IonCol size="3.5">
                <IonItem class="no-border">
                  {sitemapHtlm}
                </IonItem>
            </IonCol>
          </IonRow>    
        </IonCol>   

      </IonRow>    
      </IonGrid>
      </IonItemGroup>
      </IonContent>
      </IonPage>
 
);

};

export default Sitemap;