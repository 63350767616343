import React from 'react';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonButton,
         IonHeader, IonFooter, IonContent, IonToolbar, IonTitle, IonItem, IonGrid, IonCol, IonRow, IonItemGroup,
         IonItemDivider,  IonModal, IonSelect, IonSelectOption, isPlatform, setupConfig,
         IonMenu,
         IonMenuToggle,
         IonButtons,
         IonMenuButton,
         IonList
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { square, archive, today, list, menuOutline } from 'ionicons/icons';
import './MenuFooter.scss';

import History from '../History/History';
const history = History;

const MenuFooter: React.FC = () => {

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  
  const forwardClickHandler = (e:any, to:any)  => { 
    e.preventDefault(); 
     history.push({
         pathname: to
    });
    window.location.reload(); //for reloads since history is outside of reacr router/ mmimics what href="/gotopage" does. Todo figure out how to Linko r navigate without page relaods for footer links
    return; 
  }
   
  return (
    <>
    <IonFooter class="footer-style">
    <IonToolbar class="custom-background">
    <IonGrid>
      <IonRow class="ion-justify-content-center">
        <IonCol size-xs="15" size-sm="9" size-md="10" size-lg="10" size-xl="9">
          <IonReactRouter>
            <IonList>
              <IonButton fill="clear" color="#051a0d" class={(!isMobile)? "main-footer-label":"mobile-footer-label"} slot="start" onClick={(e) => forwardClickHandler(e,"/sitemap")}>Sitemap</IonButton>
              <IonButton fill="clear" color="#051a0d" class={(!isMobile)? "main-footer-label":"mobile-footer-label"} slot="secondary" onClick={(e) => forwardClickHandler(e,"/privacyPolicy")}>Privacy Policy</IonButton>
              <IonButton fill="clear" color="#051a0d" class={(!isMobile)? "main-footer-label":"mobile-footer-label"} slot="primary" onClick={(e) => forwardClickHandler(e,"/termsOfUse")}>Terms</IonButton>
              <IonButton fill="clear" color="#051a0d" class={(!isMobile)? "last":"last-mobile"} slot="secondary">&#169;2024 Centripet, all rights reserved</IonButton>
            </IonList>
          </IonReactRouter>
      </IonCol>
      </IonRow>
      </IonGrid>
    </IonToolbar>
    </IonFooter>
    </>
  );
};

export default MenuFooter;