import { IonCol, IonContent, IonGrid, IonItem, IonItemGroup, IonLabel, IonList, IonListHeader, IonPage, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import './PrivacyPolicy.scss';
import { fetchPrivacyPolicyContent } from '../../api/API';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const PrivacyPolicy: React.FC = () => {

const fetchContent: any = async () => {  return await  fetchPrivacyPolicyContent(); } //call to /api promise defined
const dispatch = useDispatch();
const privacypolicyContent = useSelector((state: RootStateOrAny) => state?.privacypolicycontent); //retrive redux state from the store

const addPrivacyPolicyContent:any = (cont:any) => {  //redux action and object defined
  return {
      type: "addedPrivacyPolicyOnce",
      payload: cont,
  };
};

const callPrivacyPolicyApi = () => {
  fetchContent().then((a:any) => { //call API promise to fetch content
    dispatch(addPrivacyPolicyContent(a)); //dispatch to redux reducer
  });
  return;
}

useEffect(() => {
  callPrivacyPolicyApi(); //call the api on page load once
}, []);

/*sole function to process all the privacy policy content items from the nosql collection api*/
const ProcessContentItems: any = () => {
  let result: any[] = [];
  let pCount: number = 0;
  privacypolicyContent.map((data: any, i:any) => {   
    result.push( Object.values(data.content).map((paragraphs:any, index:any) => { 
    pCount++;
    return ( 
      (data.heading=='maincontent') ? ( 
        <IonItem key={pCount} class="no-underline">
          <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
            {paragraphs}
          </p>
        </IonItem>) :
      (index==0 && data.heading!=='maincontent') ? (
      <div key={pCount}>
        <IonListHeader>
            <IonLabel class="list-header-label">{data.heading}</IonLabel>
        </IonListHeader>
        <IonItem class="no-underline">
        <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
          {paragraphs}
        </p>
        </IonItem>
      </div>
      ) :
      (<IonItem key={pCount} class="no-underline">
        <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
          {paragraphs}
        </p>
        </IonItem>) 
    );
    }
  ))
  })
  return result 
};

const GeneratePrivacyPolicyHtml = () => {
  return (
    <>
    <IonRow>
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
        <IonLabel class="top-list-header-label">Privacy Policy</IonLabel>
    </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
        <IonList lines="none">
          <ProcessContentItems/>
        </IonList>
      </IonItem>
    </IonRow>
    </>
  );
};

return (
  <IonPage>
  <IonContent>
   <IonItemGroup class="form-border-all">
      <IonGrid>
        <IonRow class="ion-justify-content-center">
          <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">
            <GeneratePrivacyPolicyHtml/>
          </IonCol>   
        </IonRow>    
    </IonGrid>
  </IonItemGroup>
  </IonContent>
  </IonPage>
);

};

export default PrivacyPolicy;