import React, { useState } from 'react';
import './Sectors.scss';
//import IndustrialImg from '../../../public/assets/icon/industril.jpg'

import { IonPage, IonContent, IonInput, IonItem, IonLabel, IonButton, IonItemGroup,
         IonModal, IonItemDivider, IonSelect, IonSelectOption,
         IonGrid, IonCol, IonRow, 
         IonCard} from '@ionic/react';

const Sectors: React.FC = () => {


  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

  const [ formErrors, setFormErrors ] = useState<any>({
     message: ""
  });

 const [showModal, setShowModal] = useState(false);

  const submit = async () => {
    try {      
     // await login({
      //  email,
      //  password
      //});
    
    } catch (e) {
      setFormErrors(e);
    }
  }
  
  return (
    <IonPage>
        <IonContent>
         <IonItemGroup class="form-border-all">
              <IonGrid>
              <IonRow class="ion-justify-content-center">
                <IonCol  size-xs="10" size-sm="9" size-md="10" size-lg="10" size-xl="9">

                <IonRow>
                    <IonCol>
                      <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Industrial</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/industrial.jpg'  alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/industrial.jpg'  alt="My Image" />
                          </IonCard>
                        }
                    </IonCol>
                    <IonCol>
                       <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Financial</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/financial.jpg' alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/financial.jpg' alt="My Image" />
                          </IonCard>
                        }
                  </IonCol>
                  <IonCol>
                       <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Healthcare</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/healthcare.jpg' alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/healthcare.jpg' alt="My Image" />
                          </IonCard>
                        }
                  </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                       <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Ecommerce</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/ecommerce.jpg' alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/ecommerce.jpg' alt="My Image" />
                          </IonCard>
                        }
                    </IonCol>
                    <IonCol>
                       <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Agriculture</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/agricultural.jpg' alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/agricultural.jpg' alt="My Image" />
                          </IonCard>
                        }
                       
                  </IonCol>
                  <IonCol>
                       <IonItem class="no-border"><IonLabel class={(!isMobile) ? "ion-label-sectors" : "ion-label-sectors-mobile"} position="stacked">Defense</IonLabel></IonItem>
                        {!isMobile ?
                          <IonItem class="no-border">
                            <img src='assets/defense.jpg' alt="My Image" />
                          </IonItem> :
                          <IonCard class="card-style">
                            <img src='assets/defense.jpg' alt="My Image" />
                          </IonCard>
                        }
                  </IonCol>
                </IonRow>
                
                </IonCol>
                </IonRow>
               </IonGrid>
                
          </IonItemGroup>
      </IonContent>
    </IonPage>
  );

};

export default Sectors;
