import { configureStore } from '@reduxjs/toolkit';
import { ServicePageReducer } from '../appReducer/ServicePageReducer';
import { AboutPageReducer } from '../appReducer/AboutPageReducer';
import { PrivacyPolicyPageReducer } from '../appReducer/PrivacyPolicyPageReducer';
import { TermsOfUseReducer } from '../appReducer/TermsOfUseReducer';

export default configureStore({
  reducer: {
    servicecontent: ServicePageReducer,
    aboutcontent: AboutPageReducer,
    privacypolicycontent: PrivacyPolicyPageReducer,
    termsofusecontent: TermsOfUseReducer,
  }
});