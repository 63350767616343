import React, { useState } from 'react';
import { IonApp
} from '@ionic/react';

import MenuDesktop from './components/MenuDesktop/MenuDesktop';
import MenuMobile from './components/MenuMobile/MenuMobile';
import MenuFooter from './components/MenuFooter/MenuFooter';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import './theme/variables.css';
import './global.scss';

import dotenv from 'dotenv';
dotenv.config();
const App: React.FC = () => {

const [showModal, setShowModal] = useState(false);
const [ formErrors, setFormErrors ] = useState<any>({
   message: ""
});

/*
setupConfig({
    tabButtonLayout: (!isPlatform('mobileweb')) ? 'icon-start' : 'icon-top',
    mode: (!isPlatform('mobileweb')) ? 'ios' : 'md'
});
*/

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

return (
  <IonApp>
  { !isMobile ? <MenuDesktop /> :  <MenuMobile /> }
  {/*todo: add to the above logic for other platforms*/}
  {/*!isPlatform('capacitor') || !isPlatform('cordova') || !isPlatform('electron') ? <MenuDesktop /> :  <MenuMobile />*/}
  <MenuFooter />
  </IonApp>
);

};

export default App;
