const initialState = [{
  id: 0,
  heading: "",
  content: ""
}];

export const AboutPageReducer = (state:any[]=initialState, action:any) => {
    switch (action.type) {
        case 'addedAboutOnce': {
          const myobj: any[] = action.payload;
          state=myobj;
          return state;
        } 
        case 'changed': {
          return state.map((t:any) => {
            if (t.id === action.id) {
              return action;
            } else {
              return t;
            }
          });
        }
        case 'deleted': {
          return state.filter((t:any) => t.id !== action.id);
        }
        default: {
            //console.log('is this default called???');
           return state;       
          //throw Error('Unknown action: ' + action.type);
        }
      }
  }